import React from 'react'

function ResumePage() {
  return (
    <div className='page'>
        
    </div>
  )
}

export default ResumePage