// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductPreview_productBox__HzRSh {
    display: block;
    height: auto;
    width: 200px;
    min-width: 200px;
    padding: 0 20px;
}

.ProductPreview_imageContainer__CBzWq {
    height: 150px;
    width: 150px;
    margin: 0 auto;
}

.ProductPreview_productInfoContainer__lbgIz {
    height: 100px;
    width: 150px;
    margin: 0 auto;
}

.ProductPreview_addToCartBtn__qJd-W {
    background-color: transparent;

    background-color: rgb(84, 203, 219);
    color: white;

    border: solid;
    border-width: 2px;
    border-color: rgb(84, 203, 219);
    border-radius: 20px;
}

.ProductPreview_addToCartBtn__qJd-W:hover {
    cursor: pointer;

    background-color: transparent;
    color: black;
    
    border: solid;
    border-width: 2px;
    border-color: rgb(84, 203, 219);
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/productPage/ProductPreview.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,6BAA6B;;IAE7B,mCAAmC;IACnC,YAAY;;IAEZ,aAAa;IACb,iBAAiB;IACjB,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,eAAe;;IAEf,6BAA6B;IAC7B,YAAY;;IAEZ,aAAa;IACb,iBAAiB;IACjB,+BAA+B;IAC/B,mBAAmB;AACvB","sourcesContent":[".productBox {\r\n    display: block;\r\n    height: auto;\r\n    width: 200px;\r\n    min-width: 200px;\r\n    padding: 0 20px;\r\n}\r\n\r\n.imageContainer {\r\n    height: 150px;\r\n    width: 150px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.productInfoContainer {\r\n    height: 100px;\r\n    width: 150px;\r\n    margin: 0 auto;\r\n}\r\n\r\n.addToCartBtn {\r\n    background-color: transparent;\r\n\r\n    background-color: rgb(84, 203, 219);\r\n    color: white;\r\n\r\n    border: solid;\r\n    border-width: 2px;\r\n    border-color: rgb(84, 203, 219);\r\n    border-radius: 20px;\r\n}\r\n\r\n.addToCartBtn:hover {\r\n    cursor: pointer;\r\n\r\n    background-color: transparent;\r\n    color: black;\r\n    \r\n    border: solid;\r\n    border-width: 2px;\r\n    border-color: rgb(84, 203, 219);\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productBox": `ProductPreview_productBox__HzRSh`,
	"imageContainer": `ProductPreview_imageContainer__CBzWq`,
	"productInfoContainer": `ProductPreview_productInfoContainer__lbgIz`,
	"addToCartBtn": `ProductPreview_addToCartBtn__qJd-W`
};
export default ___CSS_LOADER_EXPORT___;
