// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductUploadPage_inputContainer__pY9yP {
    display: flex;
    margin: 10px 0;
}

.ProductUploadPage_selectCategory__OGYdz {
    height: 35px;
    padding: 5px;
    margin-top: 5px;
    margin-right: 5px;
    
    background-color: transparent;
    /* border-width: 0; */
    /* border-bottom-width: 1px;
    border-radius: 0; */
}

.ProductUploadPage_uploadBtn__3zx0Y {
    margin-top: 25px;
    height: 35px;

    background-color: rgb(84, 203, 219);
    color: white;

    border: solid;
    border-width: 2px;
    border-color: rgb(84, 203, 219);
    border-radius: 20px;
}

.ProductUploadPage_uploadBtn__3zx0Y:hover {
    cursor: pointer;

    background-color: transparent;
    color: black;
    
    border: solid;
    border-width: 2px;
    border-color: rgb(84, 203, 219);
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/productUploadPage/ProductUploadPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,iBAAiB;;IAEjB,6BAA6B;IAC7B,qBAAqB;IACrB;uBACmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,YAAY;;IAEZ,mCAAmC;IACnC,YAAY;;IAEZ,aAAa;IACb,iBAAiB;IACjB,+BAA+B;IAC/B,mBAAmB;AACvB;;AAEA;IACI,eAAe;;IAEf,6BAA6B;IAC7B,YAAY;;IAEZ,aAAa;IACb,iBAAiB;IACjB,+BAA+B;IAC/B,mBAAmB;AACvB","sourcesContent":[".inputContainer {\r\n    display: flex;\r\n    margin: 10px 0;\r\n}\r\n\r\n.selectCategory {\r\n    height: 35px;\r\n    padding: 5px;\r\n    margin-top: 5px;\r\n    margin-right: 5px;\r\n    \r\n    background-color: transparent;\r\n    /* border-width: 0; */\r\n    /* border-bottom-width: 1px;\r\n    border-radius: 0; */\r\n}\r\n\r\n.uploadBtn {\r\n    margin-top: 25px;\r\n    height: 35px;\r\n\r\n    background-color: rgb(84, 203, 219);\r\n    color: white;\r\n\r\n    border: solid;\r\n    border-width: 2px;\r\n    border-color: rgb(84, 203, 219);\r\n    border-radius: 20px;\r\n}\r\n\r\n.uploadBtn:hover {\r\n    cursor: pointer;\r\n\r\n    background-color: transparent;\r\n    color: black;\r\n    \r\n    border: solid;\r\n    border-width: 2px;\r\n    border-color: rgb(84, 203, 219);\r\n    border-radius: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `ProductUploadPage_inputContainer__pY9yP`,
	"selectCategory": `ProductUploadPage_selectCategory__OGYdz`,
	"uploadBtn": `ProductUploadPage_uploadBtn__3zx0Y`
};
export default ___CSS_LOADER_EXPORT___;
