// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileFooter_profileFooter__uDNnN {
    /* position: fixed; */
    display: flex;
    width: 100%;
    height: 10vh;
    bottom: 0;
    left: 0;
    background-color: white;
    justify-content: right;
}

.ProfileFooter_profileFooterBox__V7TH7 {
    width: 250px;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 27px;
}

.ProfileFooter_profileFooterBoxTitle__BDPL4 {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 600;
}

.ProfileFooter_profileFooterBoxContent__zSz-D {
    font-size: 14px;
}

.ProfileFooter_profileFooterBoxContent__zSz-D:hover {
    background-color: transparent;
}

.ProfileFooter_profileFooterLink__H75jv {
    display: flex;
    width: 100px;
    margin: auto;
}

.ProfileFooter_profileIcon__pspv0 {
    width: 20px;
    height: 20px;
    margin: auto;
}

.ProfileFooter_profileIcon__pspv0:hover {
    background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/profilePages/profileFooter/ProfileFooter.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,WAAW;IACX,YAAY;IACZ,SAAS;IACT,OAAO;IACP,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".profileFooter {\r\n    /* position: fixed; */\r\n    display: flex;\r\n    width: 100%;\r\n    height: 10vh;\r\n    bottom: 0;\r\n    left: 0;\r\n    background-color: white;\r\n    justify-content: right;\r\n}\r\n\r\n.profileFooterBox {\r\n    width: 250px;\r\n    height: 100%;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    text-align: center;\r\n    padding: 27px;\r\n}\r\n\r\n.profileFooterBoxTitle {\r\n    font-size: 14px;\r\n    margin-bottom: 10px;\r\n    font-weight: 600;\r\n}\r\n\r\n.profileFooterBoxContent {\r\n    font-size: 14px;\r\n}\r\n\r\n.profileFooterBoxContent:hover {\r\n    background-color: transparent;\r\n}\r\n\r\n.profileFooterLink {\r\n    display: flex;\r\n    width: 100px;\r\n    margin: auto;\r\n}\r\n\r\n.profileIcon {\r\n    width: 20px;\r\n    height: 20px;\r\n    margin: auto;\r\n}\r\n\r\n.profileIcon:hover {\r\n    background-color: transparent;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileFooter": `ProfileFooter_profileFooter__uDNnN`,
	"profileFooterBox": `ProfileFooter_profileFooterBox__V7TH7`,
	"profileFooterBoxTitle": `ProfileFooter_profileFooterBoxTitle__BDPL4`,
	"profileFooterBoxContent": `ProfileFooter_profileFooterBoxContent__zSz-D`,
	"profileFooterLink": `ProfileFooter_profileFooterLink__H75jv`,
	"profileIcon": `ProfileFooter_profileIcon__pspv0`
};
export default ___CSS_LOADER_EXPORT___;
