// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MarketHomePage_gimmickContainer__\\+p5R0 {
    display: inline-flex;
}

.MarketHomePage_gimmickItem__gQH\\+d {
    padding: 20px;
    /* width: 300px; */
    width: 100%;
    /* width: 25%; */
    /* height: 100px; */
    
}

.MarketHomePage_gimmickItem1__op8od, .MarketHomePage_gimmickItem2__KLwqt, .MarketHomePage_gimmickItem3__O-wj4 {
    border-right: 1px solid black;
}

.MarketHomePage_gimmickImg__MMWOR {
    height: 50px;;
}

.MarketHomePage_gimmickTitle__jBS-O {
    /* font-size: 20px; */
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/marketHomePage/MarketHomePage.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".gimmickContainer {\r\n    display: inline-flex;\r\n}\r\n\r\n.gimmickItem {\r\n    padding: 20px;\r\n    /* width: 300px; */\r\n    width: 100%;\r\n    /* width: 25%; */\r\n    /* height: 100px; */\r\n    \r\n}\r\n\r\n.gimmickItem1, .gimmickItem2, .gimmickItem3 {\r\n    border-right: 1px solid black;\r\n}\r\n\r\n.gimmickImg {\r\n    height: 50px;;\r\n}\r\n\r\n.gimmickTitle {\r\n    /* font-size: 20px; */\r\n    margin: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gimmickContainer": `MarketHomePage_gimmickContainer__+p5R0`,
	"gimmickItem": `MarketHomePage_gimmickItem__gQH+d`,
	"gimmickItem1": `MarketHomePage_gimmickItem1__op8od`,
	"gimmickItem2": `MarketHomePage_gimmickItem2__KLwqt`,
	"gimmickItem3": `MarketHomePage_gimmickItem3__O-wj4`,
	"gimmickImg": `MarketHomePage_gimmickImg__MMWOR`,
	"gimmickTitle": `MarketHomePage_gimmickTitle__jBS-O`
};
export default ___CSS_LOADER_EXPORT___;
