// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductContainer_productContainer__Ptfhs {
    margin: 0 auto;
    padding: 20px 0;
    max-width: 1000px;
}

.ProductContainer_noResult__xRmPj {
    margin-bottom: 20px;
}

.ProductContainer_productGrid__yCsqz {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 0));
    flex-wrap: wrap;
    height: auto;
    /* margin: auto; */
}

.ProductContainer_pageButtonContainer__UD89M {
    display: inline-flex;
    /* margin: 15px auto; */
    /* margin-top: 15px; */
    justify-content: center;
    border-style: solid;
    border-color: grey;
    border-radius: 10px;
    border-width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/productPage/ProductContainer.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,yDAAyD;IACzD,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".productContainer {\r\n    margin: 0 auto;\r\n    padding: 20px 0;\r\n    max-width: 1000px;\r\n}\r\n\r\n.noResult {\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.productGrid {\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fit, minmax(200px, 0));\r\n    flex-wrap: wrap;\r\n    height: auto;\r\n    /* margin: auto; */\r\n}\r\n\r\n.pageButtonContainer {\r\n    display: inline-flex;\r\n    /* margin: 15px auto; */\r\n    /* margin-top: 15px; */\r\n    justify-content: center;\r\n    border-style: solid;\r\n    border-color: grey;\r\n    border-radius: 10px;\r\n    border-width: 1px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productContainer": `ProductContainer_productContainer__Ptfhs`,
	"noResult": `ProductContainer_noResult__xRmPj`,
	"productGrid": `ProductContainer_productGrid__yCsqz`,
	"pageButtonContainer": `ProductContainer_pageButtonContainer__UD89M`
};
export default ___CSS_LOADER_EXPORT___;
