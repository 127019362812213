// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .homePaage {
    max-width: 1200px;
    min-width: 1000px;
    height: auto;
    justify-content: center;
    margin:auto;
    display: block;
} */

.ImageUploader_imgUploadContainer__orbVu {
    width: 500px;
    margin: 0 auto;
    padding-top: 50px;
}

.ImageUploader_imgInputButtonContainer__T4rCr {
    /* align-content: center; */
    display: flex;
    padding: 5px auto;
}

.ImageUploader_imgInputButton__XnDUk {
    display: flex;
    width: auto;
    margin: auto 0;
    padding: 5px;

    font-size: 15px;
}

.ImageUploader_imgName__\\+eoZZ {
    margin: auto 5px;
    flex-wrap: nowrap;
}

.ImageUploader_imgUploadDropZone__\\+jqVe {
    align-content: center;
    position: relative;
    background-color: transparent;

    height: 300px;
    padding: 10px 10px 0px 10px;
    margin: 10px auto;

    border-radius: 10px;
    border: dashed;
    border-width: 1px;

    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/marketPages/productUploadPage/ImageUploader.module.css"],"names":[],"mappings":"AAAA;;;;;;;GAOG;;AAEH;IACI,YAAY;IACZ,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,2BAA2B;IAC3B,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,cAAc;IACd,YAAY;;IAEZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,kBAAkB;IAClB,6BAA6B;;IAE7B,aAAa;IACb,2BAA2B;IAC3B,iBAAiB;;IAEjB,mBAAmB;IACnB,cAAc;IACd,iBAAiB;;IAEjB,eAAe;AACnB","sourcesContent":["/* .homePaage {\r\n    max-width: 1200px;\r\n    min-width: 1000px;\r\n    height: auto;\r\n    justify-content: center;\r\n    margin:auto;\r\n    display: block;\r\n} */\r\n\r\n.imgUploadContainer {\r\n    width: 500px;\r\n    margin: 0 auto;\r\n    padding-top: 50px;\r\n}\r\n\r\n.imgInputButtonContainer {\r\n    /* align-content: center; */\r\n    display: flex;\r\n    padding: 5px auto;\r\n}\r\n\r\n.imgInputButton {\r\n    display: flex;\r\n    width: auto;\r\n    margin: auto 0;\r\n    padding: 5px;\r\n\r\n    font-size: 15px;\r\n}\r\n\r\n.imgName {\r\n    margin: auto 5px;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.imgUploadDropZone {\r\n    align-content: center;\r\n    position: relative;\r\n    background-color: transparent;\r\n\r\n    height: 300px;\r\n    padding: 10px 10px 0px 10px;\r\n    margin: 10px auto;\r\n\r\n    border-radius: 10px;\r\n    border: dashed;\r\n    border-width: 1px;\r\n\r\n    cursor: pointer;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgUploadContainer": `ImageUploader_imgUploadContainer__orbVu`,
	"imgInputButtonContainer": `ImageUploader_imgInputButtonContainer__T4rCr`,
	"imgInputButton": `ImageUploader_imgInputButton__XnDUk`,
	"imgName": `ImageUploader_imgName__+eoZZ`,
	"imgUploadDropZone": `ImageUploader_imgUploadDropZone__+jqVe`
};
export default ___CSS_LOADER_EXPORT___;
