import React from 'react'

export default function ProfilePage() {
  return (

    <div className='page'>
      {/* <div className={styles.profileSelectBox}>
        <Link>
          <div>test</div>
        </Link>
      </div>

      <div className={styles.infoBox}>
        test
      </div> */}
      This is profile page
    </div>
  )
}
