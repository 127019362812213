// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileHeader_profileHeader__Xawom {
    display: flex;
    justify-content: space-between;
    height: 10vh;
    padding: 10px 75px;
    background-color: white;
    white-space: nowrap;
}

.ProfileHeader_headerLeft__RJu3E {
    display: flex;
}

.ProfileHeader_blueDot__7qZYy {
    width: 19px;
    height: 19px;
    background-color: #0050ff;
    margin: auto;
}

.ProfileHeader_homePageBtn__kiY8j {
    padding: 10px;
    font-weight: 800;
    font-size: 25px;
}

.ProfileHeader_homePageBtn__kiY8j:hover {
    background-color: transparent;
}

.ProfileHeader_headerRight__-dc35 {
    display: flex;
}

.ProfileHeader_navBtn__FZh\\+k {
    padding: 20px;
    font-weight: 100;
    font-size: 18px;
}

.ProfileHeader_navBtn__FZh\\+k:hover {
    background-color: transparent;
}

.ProfileHeader_navBtn__FZh\\+k:visited {
   color: black;
}

.ProfileHeader_active__wbkLK {
    color: #0050ff;
}

.ProfileHeader_active__wbkLK:visited {
    color: #0050ff;
}`, "",{"version":3,"sources":["webpack://./src/profilePages/profileHeader/ProfileHeader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;GACG,YAAY;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".profileHeader {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    height: 10vh;\r\n    padding: 10px 75px;\r\n    background-color: white;\r\n    white-space: nowrap;\r\n}\r\n\r\n.headerLeft {\r\n    display: flex;\r\n}\r\n\r\n.blueDot {\r\n    width: 19px;\r\n    height: 19px;\r\n    background-color: #0050ff;\r\n    margin: auto;\r\n}\r\n\r\n.homePageBtn {\r\n    padding: 10px;\r\n    font-weight: 800;\r\n    font-size: 25px;\r\n}\r\n\r\n.homePageBtn:hover {\r\n    background-color: transparent;\r\n}\r\n\r\n.headerRight {\r\n    display: flex;\r\n}\r\n\r\n.navBtn {\r\n    padding: 20px;\r\n    font-weight: 100;\r\n    font-size: 18px;\r\n}\r\n\r\n.navBtn:hover {\r\n    background-color: transparent;\r\n}\r\n\r\n.navBtn:visited {\r\n   color: black;\r\n}\r\n\r\n.active {\r\n    color: #0050ff;\r\n}\r\n\r\n.active:visited {\r\n    color: #0050ff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileHeader": `ProfileHeader_profileHeader__Xawom`,
	"headerLeft": `ProfileHeader_headerLeft__RJu3E`,
	"blueDot": `ProfileHeader_blueDot__7qZYy`,
	"homePageBtn": `ProfileHeader_homePageBtn__kiY8j`,
	"headerRight": `ProfileHeader_headerRight__-dc35`,
	"navBtn": `ProfileHeader_navBtn__FZh+k`,
	"active": `ProfileHeader_active__wbkLK`
};
export default ___CSS_LOADER_EXPORT___;
