import React from 'react'
import SignUpTable from './SignUpTable'

function SignUpPage() {
  return (
    <div className='page'>
      
      <SignUpTable/>
    </div>
  )
}

export default SignUpPage