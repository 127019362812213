// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProjectPage_projectPageTitleContainer__mgnB7 {
    display: flex;
    padding-top: 50px;
    margin-bottom: 50px;
}

.ProjectPage_blueDot__cxYv- {
    width: 19px;
    height: 19px;
    background-color: #0050ff;
    margin: auto;
    margin-right: 10px;
}

.ProjectPage_projectPageTitle__lK8F2 {
    margin: auto;
    margin-left: 0;
    font-size: 25px;
    font-weight: 900;
}

`, "",{"version":3,"sources":["webpack://./src/profilePages/projectPage/ProjectPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".projectPageTitleContainer {\r\n    display: flex;\r\n    padding-top: 50px;\r\n    margin-bottom: 50px;\r\n}\r\n\r\n.blueDot {\r\n    width: 19px;\r\n    height: 19px;\r\n    background-color: #0050ff;\r\n    margin: auto;\r\n    margin-right: 10px;\r\n}\r\n\r\n.projectPageTitle {\r\n    margin: auto;\r\n    margin-left: 0;\r\n    font-size: 25px;\r\n    font-weight: 900;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"projectPageTitleContainer": `ProjectPage_projectPageTitleContainer__mgnB7`,
	"blueDot": `ProjectPage_blueDot__cxYv-`,
	"projectPageTitle": `ProjectPage_projectPageTitle__lK8F2`
};
export default ___CSS_LOADER_EXPORT___;
