// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageButton_pageButton__q62EI {
    width: 50px;
    height: 50px;
}

.PageButton_clickable__pDt9K:hover {
    background-color: #e0c59393;
}

.PageButton_currentPage__A1Uq4 {
    border-style: solid;
    border-radius: 5px;
    /* border-color: grey; */
    border-width: 1px;
}

.PageButton_prevPageButton__2rMX-, .PageButton_nextPageButton__uqD-z {
    width: 200px;
    height: 50px;
    position: relative;
}

.PageButton_prevPageButton__2rMX-::after, .PageButton_nextPageButton__uqD-z::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 2px;
    height: 45px;
    background-color: grey;
}

.PageButton_prevPageButton__2rMX-::after {
    right: 0px;
    margin-right: 5px;
}

.PageButton_nextPageButton__uqD-z::before {
    left: 0px;
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/component/PageButton.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,wBAAwB;IACxB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI,SAAS;IACT,gBAAgB;AACpB","sourcesContent":[".pageButton {\r\n    width: 50px;\r\n    height: 50px;\r\n}\r\n\r\n.clickable:hover {\r\n    background-color: #e0c59393;\r\n}\r\n\r\n.currentPage {\r\n    border-style: solid;\r\n    border-radius: 5px;\r\n    /* border-color: grey; */\r\n    border-width: 1px;\r\n}\r\n\r\n.prevPageButton, .nextPageButton {\r\n    width: 200px;\r\n    height: 50px;\r\n    position: relative;\r\n}\r\n\r\n.prevPageButton::after, .nextPageButton::before {\r\n    content: '';\r\n    display: inline-block;\r\n    position: absolute;\r\n    width: 2px;\r\n    height: 45px;\r\n    background-color: grey;\r\n}\r\n\r\n.prevPageButton::after {\r\n    right: 0px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.nextPageButton::before {\r\n    left: 0px;\r\n    margin-left: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageButton": `PageButton_pageButton__q62EI`,
	"clickable": `PageButton_clickable__pDt9K`,
	"currentPage": `PageButton_currentPage__A1Uq4`,
	"prevPageButton": `PageButton_prevPageButton__2rMX-`,
	"nextPageButton": `PageButton_nextPageButton__uqD-z`
};
export default ___CSS_LOADER_EXPORT___;
