// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Gimmick_gimmickContainer__YZfDi {
    display: flex;
}

.Gimmick_gimmickItem__JAO3i {
    padding: 20px;
    margin: auto;
    /* width: 300px; */
    width: 100%;
    /* width: 25%; */
    /* height: 100px; */
    
}

.Gimmick_gimmickItem1__p2lsD, .Gimmick_gimmickItem2__iOMzB, .Gimmick_gimmickItem3__ib\\+to {
    border-right: 1px solid black;
}

.Gimmick_gimmickImg__Zhrby {
    height: 50px;;
}

.Gimmick_gimmickTitle__P-9Wf {
    /* font-size: 20px; */
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/marketHomePage/Gimmick.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":[".gimmickContainer {\r\n    display: flex;\r\n}\r\n\r\n.gimmickItem {\r\n    padding: 20px;\r\n    margin: auto;\r\n    /* width: 300px; */\r\n    width: 100%;\r\n    /* width: 25%; */\r\n    /* height: 100px; */\r\n    \r\n}\r\n\r\n.gimmickItem1, .gimmickItem2, .gimmickItem3 {\r\n    border-right: 1px solid black;\r\n}\r\n\r\n.gimmickImg {\r\n    height: 50px;;\r\n}\r\n\r\n.gimmickTitle {\r\n    /* font-size: 20px; */\r\n    margin: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gimmickContainer": `Gimmick_gimmickContainer__YZfDi`,
	"gimmickItem": `Gimmick_gimmickItem__JAO3i`,
	"gimmickItem1": `Gimmick_gimmickItem1__p2lsD`,
	"gimmickItem2": `Gimmick_gimmickItem2__iOMzB`,
	"gimmickItem3": `Gimmick_gimmickItem3__ib+to`,
	"gimmickImg": `Gimmick_gimmickImg__Zhrby`,
	"gimmickTitle": `Gimmick_gimmickTitle__P-9Wf`
};
export default ___CSS_LOADER_EXPORT___;
