// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainPage_pageContainer__CylIT {
    display: block;
    /* width: 90%; */
    margin: 0 auto;
    /* background-color: white; */
}`, "",{"version":3,"sources":["webpack://./src/MainPage.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,6BAA6B;AACjC","sourcesContent":[".pageContainer {\r\n    display: block;\r\n    /* width: 90%; */\r\n    margin: 0 auto;\r\n    /* background-color: white; */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `MainPage_pageContainer__CylIT`
};
export default ___CSS_LOADER_EXPORT___;
