// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProductPage_productPage__P1Zax {
    display: flex;
}

.ProductPage_filter__M3-gk {
    width: 300px;
    border-style: solid;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/marketPages/productPage/ProductPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".productPage {\r\n    display: flex;\r\n}\r\n\r\n.filter {\r\n    width: 300px;\r\n    border-style: solid;\r\n    height: auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productPage": `ProductPage_productPage__P1Zax`,
	"filter": `ProductPage_filter__M3-gk`
};
export default ___CSS_LOADER_EXPORT___;
